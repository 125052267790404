import React, { useEffect, useRef, useState } from 'react';
import * as S from './styles';
import { isMobile } from 'react-device-detect';
import semFoto from '../../../../assets/images/muitoMaisSemFoto.webp'

interface Props {
    fotos: string[];
    codVeiculo: string;
    activeIndex: number;
    setImageOpen: (arg: boolean) => void
    setActiveIndex: (arg: number) => void
}

export const CaroselVeiculo: React.FC<Props> = ({ fotos, codVeiculo, setImageOpen, setActiveIndex, activeIndex }) => {
    useEffect(() => {
        function handleKeyDown(event: { code: string; }) {
            if (event.code === 'ArrowLeft') {
                setActiveIndex((activeIndex - 1 + fotos.length) % fotos.length);
            } else if (event.code === 'ArrowRight') {
                setActiveIndex((activeIndex + 1) % fotos.length);
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeIndex, fotos.length]);



    const handlePrevClick = () => {
        setActiveIndex((activeIndex - 1 + fotos.length) % fotos.length);
    };

    const handleNextClick = () => {
        setActiveIndex((activeIndex + 1) % fotos.length);
    };

    const handleThumbnailClick = (index: number) => {
        setActiveIndex(index);
    };

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const divRef = useRef<HTMLDivElement>(null);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setStartX(e.pageX - (divRef.current?.offsetLeft ?? 0));
        setScrollLeft(divRef.current?.scrollLeft ?? 0);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };


    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging || !divRef.current) return;
        e.preventDefault();
        const x = e.pageX - (divRef.current?.offsetLeft ?? 0);
        const scrollX = (x - startX) * 1.5;
        divRef.current.scrollLeft = scrollLeft - scrollX;
    };

    return (
        <S.ImageCarouselWrapper>
            {isMobile ? (
                <div className='main'>
                    <i className="ri-arrow-left-s-line ri-2x" onClick={handlePrevClick}></i>
                        <img onClick={() => { setImageOpen(true) }} className='image-main' src={fotos.length === 0 ? semFoto : `https://litoralcar.com.br/foto-resize-site/X/${codVeiculo}/${process.env.REACT_APP_COD_LOJA}/${fotos[activeIndex]}`} alt="Imagem do carro" />
                    <i className="ri-arrow-right-s-line ri-2x" onClick={handleNextClick}></i>
                </div>
            ) : (
                <>
                    <div className='main'>
                    <i className="ri-arrow-left-s-line ri-2x" onClick={handlePrevClick}></i>
                        <img onClick={() => { setImageOpen(true) }} className='image-main' src={fotos.length === 0 ? semFoto : `https://litoralcar.com.br/foto-resize-site/X/${codVeiculo}/${process.env.REACT_APP_COD_LOJA}/${fotos[activeIndex]}`} alt="" />
                    <i className="ri-arrow-right-s-line ri-2x" onClick={handleNextClick}></i>
                    </div>
                    <S.ThumbnailsWrapper
                        className="scrollable"
                        ref={divRef}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                    >
                    {fotos.map((foto, index) => (
                        <S.Thumbnail
                            key={`https://litoralcar.com.br/foto-resize-site/X/${codVeiculo}/${process.env.REACT_APP_COD_LOJA}/${foto}`}
                            src={`https://litoralcar.com.br/foto-resize-site/X/${codVeiculo}/${process.env.REACT_APP_COD_LOJA}/${foto}`}
                            onClick={() => handleThumbnailClick(index)}
                            style={{ opacity: index === activeIndex ? 1 : 0.5 }}
                        />
                    ))}
                    </S.ThumbnailsWrapper>
                </>
            )}
           
        </S.ImageCarouselWrapper>
    );
};