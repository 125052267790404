import styled from 'styled-components';
import { Cores } from '../../shared/utils/styles';

export const Card = styled.div`
    background-color: transparent;
    display:flex;
    flex-direction:column;
    margin:10px;
    width: 31.3%;
    border: 6px solid transparent;
    padding: 10px;

    :hover {
        border: 1px solid #CF8E4D;
        padding: 15px;
        border-radius: 15px;
    }
    >img{
        cursor: pointer;
        border-radius: 14px;
        max-width: 100%;
    }
    @media (max-width: 1200px) {
        width:90vw !important;
    }

    >div{
        color:${Cores.PRETO};
    }
    >span{
        margin-left:10px;
    }
    @media (max-width: 991px) {
        border: 6px solid transparent !important;
        padding: 0px;
        width:97vw !important;
        :hover {
        border: 1px solid transparent;
        padding: 0px;
        border-radius: 15px;
    }
    }
`
export const Veiculo = styled.div`
    width:100%;
    display:flex;
    white-space: break-spaces;
    text-transform:uppercase;
    flex-direction:column;
`

export const InfoCard = styled.div`
    color: white;
    width: 100%;
    overflow: hidden;


    h2 {
        font-size: medium;
        font-weight: 300;
        color: #E2B66B;
    }
    h4 {
        font-size: large;
        margin-top: 10px;
        font-weight: 400;
    }
    h5 {
        font-weight: 600;
        font-size: 14px;
        margin-top: 5px;
        text-transform: capitalize;
    }
    span {
        padding: 15px 15px 0px 0px;
       word-wrap: break-word;
       max-height: 100px;
       display: flex;
       flex-direction: row;
       gap: 10px;
       overflow: hidden;
    }
    @media (max-width: 991px) {
        justify-content: center;
        align-items: center;
        text-align: center;
        h5 {
            margin-top: 15px;
        }
        span {
            height: auto;
            max-height:auto;
        }
    }

`

export const LoadingCard = styled.div`
    display: flex;
    align-items: center;
    height: 309.86px;
    width: 100%;
    justify-content: center;
    background-color: ${Cores.CINZA_300};
    >span{
        color: ${Cores.BRANCO};
        font-size: 35px;
        padding: 20px;
    }
`