import styled from "styled-components";
import { Cores } from "../../shared/utils/styles";

export const Container = styled.div`
    background-color: ${Cores.PRETO};
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0;

    .container {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    .insta-grid {
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px !important;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: 100% !important;
        }
    }
    .container-img {
        border-radius: 5px !important;
        max-width: calc(50vw / 7);
        max-height: calc(50vw / 7);
        min-width: 150px;
        min-height: 150px;
        overflow: hidden;
    }

    div{
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        >img {
            max-width: 300px;
        }
    }
    img {
        max-width: 450px;
    }
    h2 {
        text-align: center;
        color: ${Cores.BRANCO};
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .hr {
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
        width: 140px;
    }
    span {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const ContainerMobile = styled.div`
    background-color: ${Cores.PRETO};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    gap: 50px;
    .hr {
        height: 1px;
        background: linear-gradient(to right, #CD8A49 0%, #F8E58F 100%);
        border-radius: 5px;
        width: 140px;
    }
    span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    h2 {
        text-align: center;
        color: ${Cores.BRANCO};
        font-size: 15px;
    }
    img {
        max-width: 300px;
    }
    @media (max-width: 991px) {
        img {
            max-width: 100px;
        }
    }
    .row {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
`