import { useEffect } from 'react'
import CookieButton from '../../components/Cookies'
import { WhatsappFlutuante } from '../../components/WhatsappFlutuante'
import { Header } from "../../components/Header"
import { ConteudoContato } from './ConteudoContato';
import Metatags from '../../components/Metatags';
export const Contato = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
            <Metatags title="Muito Mais Carros" description="Muito Mais Carros - O lugar certo para sair de carro novo!" url={window.location.href} img={`${process.env.PUBLIC_URL}/LogoLoja.ico`} />
            <Header titulo="Contato" />
            <ConteudoContato />
            <WhatsappFlutuante />
            <CookieButton />
        </div>
    )
}