import { useEffect, useState } from 'react';
import * as S from './styles'
import Cookies from 'js-cookie';
import Input from 'antd/es/input/Input';
import { Cores } from '../../../shared/utils/styles';
import { getLogin } from '../../../service/apiLitoral';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const Login = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const loginCookies = Cookies.get('auth');

    useEffect(()=> {
        if(loginCookies) {
            navigate('/admin/home')
        }
    },[])

    useEffect(()=> {
        if(loginCookies) {
            navigate('/admin/home')
        }
    },[])

    async function handleLogin() {
        const result = await getLogin({
            login: login,
            senha: password
        })
            if(result?.status === 200) {
                Cookies.set('auth', 'true', { expires: 30 / (24 * 60) });
                Cookies.set('usuario', login, { expires: 30 / (24 * 60) });
                Cookies.set('senha', password, { expires: 30 / (24 * 60) });
                Cookies.set('token', result.data, { expires: 30 / (24 * 60) });
                navigate('/admin/home')
                window.location.reload()
            } else {
                toast.error("Login ou senha incorretos!")
                Cookies.set('auth', 'false');
            }
      }

    return (
        <S.Container>
            <div>
                <h2>Login</h2>
                <Input style={{backgroundColor: Cores.CINZA_100}} onChange={(e) => {setLogin(e.target.value)}} placeholder='Usuario' />
                <Input onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleLogin();
                      }
                }} style={{backgroundColor: Cores.CINZA_100}} type='password' onChange={(e) => {setPassword(e.target.value)}} placeholder='Senha' />
                <button onClick={() => {handleLogin()}} type='button' style={{backgroundColor: Cores.VERDE_400}}>Entrar</button>
            </div>
        </S.Container>
    )
}