import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "../../App"
import { WhatsappFlutuante } from "../../components/WhatsappFlutuante"
import { filterEstoque, filterEstoqueByBusca, getEstoque, populateInput, resetInput, setInputSelected } from "../../shared/redux/slices/litoralEstoque"
import { Header } from "../../components/Header"
import * as S from './styles'
import { EstoqueConteudo } from "./EstoqueConteudo"
import { getEstoque as getEstoqueSelector } from "../../shared/redux/selectors"
import Metatags from "../../components/Metatags"
import { useLocation } from "react-router-dom"

export const Estoque = () => {
    const dispatch = useDispatch<AppDispatch>()
    const getEstoqueData = useSelector(getEstoqueSelector)
    const location = useLocation()
    const queryParameters = new URLSearchParams(window.location.search)

    const categoriaParams = queryParameters.get("categoria")
    const marcaParams = queryParameters.get("marca")
    const modeloParams = queryParameters.get("modelo")
    const valorDeParams = queryParameters.get("valorDe")
    const valorAteParams = queryParameters.get("valorAte")
    const buscaParams = queryParameters.get("busca")

    useEffect(() => {
        if(!categoriaParams) {
            dispatch(resetInput({tipo: "categoria"}))
        }
    },[])

    useEffect(() => {
        if(getEstoqueData.length > 1) {
            return
        } else {
            dispatch(getEstoque())
            dispatch(populateInput())
            if (buscaParams) dispatch(filterEstoqueByBusca(buscaParams))
        }
    }, [dispatch])

    if (getEstoqueData.length > 1) {
        dispatch(filterEstoque())
        dispatch(populateInput())
        if (buscaParams) dispatch(filterEstoqueByBusca(buscaParams))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(() => {

        dispatch(filterEstoque())

        if(window.location.search.includes('Moto')) {
            dispatch(setInputSelected({index: 'Moto', tipo: "categoria"}))
        }
        if(window.location.search.includes('Carro%2FCamionetas')) {
            dispatch(setInputSelected({index: 'Carro/Camionetas', tipo: "categoria"}))
        }
        dispatch(resetInput({tipo:'combustivel'}))
        dispatch(resetInput({tipo:'opcionais'}))
        dispatch(resetInput({tipo:'valorPre'}))
        dispatch(resetInput({tipo:'valorPos'}))
        dispatch(resetInput({tipo:'marca'}))
        dispatch(resetInput({tipo:'modelo'}))
        dispatch(resetInput({tipo:'anoPos'}))
        dispatch(resetInput({tipo:'anoPre'}))

            
            if(categoriaParams) {
                dispatch(setInputSelected({index: categoriaParams, tipo: "categoria"}))
            }
            if(marcaParams) {
                dispatch(setInputSelected({index: marcaParams, tipo: "marca"}))
            }
            if(modeloParams) {
                dispatch(setInputSelected({index: modeloParams, tipo: "modelo"}))
            }
            if(valorDeParams) {
                dispatch(setInputSelected({index: valorDeParams, tipo: "valorPre"}))
            }
            if(valorAteParams) {
                dispatch(setInputSelected({index: valorAteParams, tipo: "valorPos"}))
            }
    
          dispatch(populateInput())
          if (buscaParams) {
            dispatch(filterEstoqueByBusca(buscaParams))
            return
        } 

      }, [location]);

      useEffect(() => {
        if (buscaParams) {
            dispatch(filterEstoqueByBusca(buscaParams))
            return
        } 
      },[buscaParams])

    return (
        <div>
            <Metatags title="Muito Mais Carros" description="Muito Mais Carros - O lugar certo para sair de carro novo!" url={window.location.href} img={`${process.env.PUBLIC_URL}/LogoLoja.ico`} />
            <Header titulo="Estoque" />
                <S.Container>
                    <EstoqueConteudo />
                </S.Container>
            <WhatsappFlutuante />
        </div>
    )
}