import axios from "axios";
import CryptoJS from "crypto-js";
import { EmailAvaliacao, EmailContato, EmailProposta } from "../shared/types";
import { EnvioEmailAvaliacao, EnvioEmailContato, EnvioEmailProposta as EnvioEmailPropostaDictionary } from "../shared/utils/dictionary";
import Cookies from "js-cookie";
import { ThrowErrorEvent } from "../shared/utils/error";

const apiLitoral = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const apiLitoralAdm = axios.create({
  baseURL: process.env.REACT_APP_API_ADMIN,
});

const tokenCookies = Cookies.get('token');

const data = {
  "username": process.env.REACT_APP_USER,
  "password": process.env.REACT_APP_PASSWORD,
};

const acesso = "kYCPkJXlsMoQOzuHNJFezOzaHSsgIHtC3WKmOg67Af5OZpQ7MVY7G"
const cod_loja = Number(process.env.REACT_APP_COD_LOJA)

const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_CHAVE_API}`).toString();

export const getEstoque = () => {
  try {
    return apiLitoral.post<any>(`/buscar/estoque/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    ThrowErrorEvent("buscar estoque")
  }
};

export const getVeiculo = (codVeiculo : string) => {
  try {
    return apiLitoral.post<any>(`/buscar/veiculo/${codVeiculo}/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    ThrowErrorEvent("buscar o veículo")
  }
};

export const getLoja = () => {
  try {
    return apiLitoral.post<any>(`/buscar/loja/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    ThrowErrorEvent("buscar informações da loja")
  }
};

export const getTelefones = () => {
  try {
    return apiLitoral.post<any>(`/buscar/telefones/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    ThrowErrorEvent()
  }
};

export const getEnderecos = () => {
  try {
    return apiLitoral.post<any>(`/buscar/enderecos/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    ThrowErrorEvent()
  }
};

export const getEmails = () => {
  try {
    return apiLitoral.post<any>(`/buscar/emails/${process.env.REACT_APP_COD_LOJA}`, {data: encryptedData});
  } catch (error) {
    ThrowErrorEvent()
  }
};

export const getEmailsContato = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/email-contato/buscar`, {headers: {
      'token': `${tokenCookies}`,
  }});
  } catch (error) {
    ThrowErrorEvent("buscar emails")
  }
};
export const getEmailsAvaliacao = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/avaliacao/buscar`, {headers: {
      'token': `${tokenCookies}`,
  }});
  } catch (error) {
    ThrowErrorEvent("buscar emails sobre avaliações")
  }
};
export const getEmailsProposta = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/proposta/buscar`, {headers: {
      'token': `${tokenCookies}`,
  }});
  } catch (error) {
    ThrowErrorEvent("buscar emails sobre proposta")
  }
};

export const postEmailContato = (data: EmailContato) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/email-contato/salvar`,{
      [EnvioEmailContato.CIDADE]: data.cidade,
      [EnvioEmailContato.EMAIL]: data.email,
      [EnvioEmailContato.EMAIL_LOJA]: data.emailLoja,
      [EnvioEmailContato.MENSAGEM]: data.mensagem,
      [EnvioEmailContato.NOME]: data.nome,
      [EnvioEmailContato.TELEFONE]: data.telefone,
      lojaId: cod_loja,
    }, {headers: {
      'access': acesso,
  }});
  } catch (error) {
    ThrowErrorEvent("buscar emails de contato")
  }
};

export const postEmailAvaliacao = (data: EmailAvaliacao) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/avaliacao/salvar`,{
      [EnvioEmailAvaliacao.ANO_FABRICACAO]: data.ano_fabricacao,
      [EnvioEmailAvaliacao.ANO_MODELO]: data.ano_modelo,
      [EnvioEmailAvaliacao.CELULAR]: data.celular,
      [EnvioEmailAvaliacao.COMBUSTIVEL]: data.combustivel,
      [EnvioEmailAvaliacao.COR]: data.cor,
      [EnvioEmailAvaliacao.EMAIL]: data.email,
      [EnvioEmailAvaliacao.MARCA]: data.marca,
      [EnvioEmailAvaliacao.MODELO]: data.modelo,
      [EnvioEmailAvaliacao.MOTIVO]: data.motivo,
      [EnvioEmailAvaliacao.NOME]: data.nome,
      [EnvioEmailAvaliacao.EMAIL_LOJA]: data.emailLoja,
      lojaId: cod_loja,
    }, {headers: {
      'access': acesso,
  }});
  } catch (error) {
    ThrowErrorEvent("enviar o email")
  }
};

export const postEmailProposta= (data: EmailProposta) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/proposta/salvar`,{
            [EnvioEmailPropostaDictionary.CIDADE]: data.cidade,
            [EnvioEmailPropostaDictionary.EMAIL]: data.email,
            [EnvioEmailPropostaDictionary.MENSAGEM]: data.mensagem,
            [EnvioEmailPropostaDictionary.NOME]: data.nome,
            [EnvioEmailPropostaDictionary.TELEFONE]: data.telefone,
            [EnvioEmailPropostaDictionary.UF]: data.uf,
            [EnvioEmailPropostaDictionary.VEICULO]: data.veiculo,
            [EnvioEmailPropostaDictionary.EMAIL_LOJA]: data.emailLoja,
            lojaId: cod_loja,
          }, {headers: {
            'access': acesso,
        }});
  } catch (error) {
    ThrowErrorEvent("enviar o email")
  }
};

export const getInfosLoja = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/loja/buscar?id=${process.env.REACT_APP_COD_LOJA}`, {
      headers: {
        'access': acesso
      }
    });
  } catch (error) {
    ThrowErrorEvent("buscar informações da loja")
  }
};
export const getBanner = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/banner/buscar?lojaId=${process.env.REACT_APP_COD_LOJA}` , {headers: {
        'access': acesso
    }});
  } catch (error) {
    ThrowErrorEvent("buscar os banners")
  }
};

export const postBanner = (formData: any) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/banner/salvar`, formData, {headers: {
      'token': `${tokenCookies}`,
      'Content-Type': 'multipart/form-data'
    }});
  } catch (error) {
    ThrowErrorEvent("cadastrar o banner")
  }
};
export const getVideo = () => { // TODO
  try {
    return apiLitoralAdm.get<any>(`/video/buscar?lojaId=${process.env.REACT_APP_COD_LOJA}`, {headers: {
      'access': acesso,
  }});
  } catch (error) {
    ThrowErrorEvent("buscar vídeos")
  }
};

export const postVideo = async (formData: any) => { // FEITO
  try {
    const response = await apiLitoralAdm.post<any>(`/video/salvar`, formData, {headers: {
      'token': tokenCookies,
      'Content-Type': 'multipart/form-data'
    }});
    return response;
  } catch (error) {
    ThrowErrorEvent("cadastrar o vídeo, tamanho máx. 10mb");
  }
};

export const deleteBanner = (id: string) => { // FEITO
  try {
    return apiLitoralAdm.delete<any>(`/banner/deletar?id=${id}`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("deletar o banner")
  }
};
export const deleteVideo = (id: string) => { // FEITO
  try {
    return apiLitoralAdm.delete<any>(`/video/deletar?id=${id}`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("deletar o vídeo")
  }
};

export const putInfos = (data: any) => { // FEITO
  try {
    return apiLitoralAdm.put<any>(`/infos-loja/alterar`, data, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("editar as informações da loja")
  }
};

export const postVisitas = (data: any) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/acesso/salvar`, data, {headers: {
        'access': `${acesso}`,
    }});
  } catch (error) {
    ThrowErrorEvent()
  }
};
export const postWhats = (data: any) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/whatsapp-cliques/salvar`, data, {headers: {
        'access': `${acesso}`,
    }});
  } catch (error) {
    ThrowErrorEvent()
  }
};

export const postFuncionario = (data: any) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/funcionario/salvar`, data, {headers: {
      'token': `${tokenCookies}`,
      'Content-Type': 'multipart/form-data'
    }});
  } catch (error) {
    ThrowErrorEvent("cadastrar funcionario")
  }
};

export const postNewsLetter = (data: any) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/newsletter/salvar`, data, {headers: {
      'access': `${acesso}`,
  }});
  } catch (error) {
    ThrowErrorEvent("cadastrar funcionario")
  }
};

export const getNewsLetter = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/newsletter/buscar`, {headers: {
      'token': `${tokenCookies}`,
  }});
  } catch (error) {
    ThrowErrorEvent("buscar e-mails do News Letter")
  }
};

export const getFuncionarios = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/funcionario/buscar?idLoja=${process.env.REACT_APP_COD_LOJA}`, {headers: {
      'access': `${acesso}`,
  }});
  } catch (error) {
    ThrowErrorEvent("buscar funcionarios")
  }
};
export const getWhatsApp = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/whatsapp-cliques/buscar`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("buscar whatsApps")
  }
};

export const deleteFuncionario = (id: string) => { // FEITO
  try {
    return apiLitoralAdm.delete<any>(`/funcionario/deletar?id=${id}`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("deletar o funcionario")
  }
};
export const buscarVisitasDias = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/acesso/buscar?periodo=dias`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("buscar as visitas")
  }
};
export const buscarVisitasMeses = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/acesso/buscar?periodo=meses`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("buscar as visitas")
  }
};
export const buscarVisitasAnos = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/acesso/buscar?periodo=anos`, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("buscar as visitas")
  }
};
export const buscarSlide = () => { // FEITO
  try {
    return apiLitoralAdm.get<any>(`/slide/ativo?lojaId=${process.env.REACT_APP_COD_LOJA}` , {headers: {
      'access': acesso,
  }});
  } catch (error) {
    ThrowErrorEvent()
  }
};
export const getLogin = (data: { login: string; senha: string; }) => { // FEITO
  try {
    return apiLitoralAdm.post<any>(`/auth/login` , {
      email: data.login,
      senha: data.senha,
      lojaId: Number(cod_loja)
    }, {headers: {
      'access': acesso
  }});
  } catch (error) {
  //  
}
};
export const editBanner = ({data}: any) => { // FEITO
  try {
    return apiLitoralAdm.put<any>(`/banner/alterar`, data, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("editar o banner")
  }
};
export const editVideo = ({data}: any) => { // FEITO
  try {
    return apiLitoralAdm.put<any>(`/video/alterar`, data, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("editar o video")
  }
};
export const editFuncionario = ({data}: any) => { // FEITO
  try {
    return apiLitoralAdm.put<any>(`/funcionario/alterar`, data, {headers: {
      'token': `${tokenCookies}`,
    }});
  } catch (error) {
    ThrowErrorEvent("editar o funcionario")
  }
};
