import * as S from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getInputs } from "../../../shared/redux/selectors";
import { Input, Select } from "antd";
import { useState } from "react";
import { AppDispatch } from "../../../App";
import { populateInput, resetInput, setInputSelected } from "../../../shared/redux/slices/litoralEstoque";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { List } from "react-virtualized";

export const Busca = () => {
  const [text, setText] = useState('')
  const [tipoBusca, setTipoBusca] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const inputs = useSelector(getInputs);
  const url = new URL(window.location.toString());
  const parametrosDaURL = new URLSearchParams(window.location.search);
     
  const optionsCategoria = inputs.data.categoria.map((x) => {
      return {value: x, label: x }
  })
  const optionsMarca = inputs.data.marca.map((x) => {
      return {value: x, label: x }
  }).sort((a, b) => {
    if (a.label === "Marca") {
      return -1;
    }
    else if (b.label === "Marca") {
      return 1;
    }
    else {
      return a.label.localeCompare(b.label);
    }
  });
  const optionsModelo = inputs.data.modelo.map((x) => {
      return {value: x, label: x }
  }).sort((a, b) => {
    if (a.label === "Modelo") {
      return -1;
    }
    else if (b.label === "Modelo") {
      return 1;
    }
    else {
      return a.label.localeCompare(b.label);
    }
  });
  const optionsValorPre = inputs.data.valorPre.map((x) => {
    return {value: x, label: Number(x) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(x)) : x}  })
  const optionsValorPos = inputs.data.valorPos.map((x) => {
      return {value: x, label: Number(x) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(x)) : x}
  })
  const listaOptionsValorPosReversa = [...optionsValorPos].reverse()

  const handleChange = (event: any, tipo: 'categoria'| 'marca' | 'modelo' | 'anoPre' | 'anoPos' | 'valorPre' | 'valorPos' | 'combustivel' | 'opcionais') => {
    dispatch(setInputSelected({index: event, tipo: tipo}))
};

  return (
    <S.Container>
      <div style={tipoBusca ? {display: "none"} : {}}>
        <Input
          className="input-buscar"
          onChange={(event) => {setText(event.target.value)}}
          style={{ width: 500, fontWeight: "bold" }}
          placeholder="Busque por modelo ou modelo"
          bordered={true}
          onKeyDown={(event) => {
            if(event.key === 'Enter') {
              dispatch(populateInput())
              navigate(`/estoque?busca=${text.toLocaleLowerCase()}`)
            }
          }}
        />
        {isMobile ? (
          <div className="mobile-div">
            <S.botaoEstoque>
            <a onClick={() => {
              dispatch(populateInput())
              navigate(`/estoque?busca=${text.toLocaleLowerCase()}`)
            }}>
                Buscar resultado
            </a>
          </S.botaoEstoque>
          <S.botaoEstoque>
              <a className="icon-button" onClick={() => {
                setTipoBusca(!tipoBusca)
              }}>
                  <i className="ri-equalizer-line ri-lg"></i>
              </a>
          </S.botaoEstoque>
          </div>
        ) : (
          <>
          <S.botaoEstoque>
            <a onClick={() => {
              dispatch(populateInput())
              navigate(`/estoque?busca=${text.toLocaleLowerCase()}`)
            }}>
                Buscar resultado
            </a>
        </S.botaoEstoque>
        <S.botaoEstoque>
            <a className="icon-button" onClick={() => {
              setTipoBusca(!tipoBusca)
            }}>
                <i className="ri-equalizer-line ri-lg"></i>
            </a>
        </S.botaoEstoque>
          </>
        )}
      </div>
      <div style={!tipoBusca ? {display: "none"} : {}}>
      <Select
        defaultValue={"Categoria"}
        value={inputs.selected.categoria !== '' ? inputs.selected.categoria : 'Categoria'}
        onChange={(event) => {
            dispatch(resetInput({tipo:'combustivel'}))
            dispatch(resetInput({tipo:'opcionais'}))
            dispatch(resetInput({tipo:'valorPre'}))
            dispatch(resetInput({tipo:'valorPos'}))
            dispatch(resetInput({tipo:'marca'}))
            dispatch(resetInput({tipo:'modelo'}))
            dispatch(resetInput({tipo:'anoPos'}))
            dispatch(resetInput({tipo:'anoPre'}))
            handleChange(event, "categoria")
            dispatch(populateInput())

            if(event === "Categoria") {
              url.searchParams.delete('categoria');
          } else {
              url.searchParams.set('categoria', event);
          }
            url.searchParams.delete('marca');
            url.searchParams.delete('modelo');
            url.searchParams.delete('busca');
            url.searchParams.delete('valorDe');
            url.searchParams.delete('valorAte');
            window.history.pushState({}, '', url);
        }}
        options={optionsCategoria}
        suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
        />
        <Select
        defaultValue="Marca"
        value={inputs.selected.marca !== '' ? inputs.selected.marca : 'Marca'}
        onChange={(event) => {
            dispatch(resetInput({tipo:'combustivel'}))
            dispatch(resetInput({tipo:'opcionais'}))
            dispatch(resetInput({tipo:'valorPre'}))
            dispatch(resetInput({tipo:'valorPos'}))
            dispatch(resetInput({tipo:'modelo'}))
            dispatch(resetInput({tipo:'anoPos'}))
            dispatch(resetInput({tipo:'anoPre'}))
            handleChange(event, "marca")
            dispatch(populateInput())

            if(event === "Marca") {
                url.searchParams.delete('marca');
            } else {
                url.searchParams.set('marca', event);
            }
            url.searchParams.delete('modelo');
            url.searchParams.delete('busca');
            url.searchParams.delete('valorDe');
            url.searchParams.delete('valorAte');
            window.history.pushState({}, '', url);
        }}
        options={optionsMarca}
        suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
        />
        <Select
            defaultValue="Modelo"
            value={inputs.selected.modelo !== '' ? inputs.selected.modelo : 'Modelo'}
            onChange={(event) => {
                dispatch(resetInput({tipo:'combustivel'}))
                dispatch(resetInput({tipo:'opcionais'}))
                dispatch(resetInput({tipo:'valorPre'}))
                dispatch(resetInput({tipo:'valorPos'}))
                dispatch(resetInput({tipo:'anoPos'}))
                dispatch(resetInput({tipo:'anoPre'}))
                handleChange(event, "modelo")
                dispatch(populateInput())

                if(event === "Modelo") {
                    url.searchParams.delete('modelo');
                } else {
                    url.searchParams.set('modelo', event);
                }
                url.searchParams.delete('busca');
                url.searchParams.delete('valorDe');
                url.searchParams.delete('valorAte');
                window.history.pushState({}, '', url);
            }}
            options={optionsModelo}
            suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
            />
            {isMobile ? (
              <div className="div-valor">
              <Select
                defaultValue="Preço de"
                value={inputs.selected.valorPre !== '' ? inputs.selected.valorPre : 'Preço de'}
                options={optionsValorPre}
                suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
                dropdownRender={() => (
                  <List
                    rowCount={optionsValorPre.length}
                    rowHeight={30}
                    width={180}
                    height={Math.min(optionsValorPre.length * 30, 200)}
                    style={{ overflow: 'auto' }}
                    rowRenderer={({ index, key, style }: any) => {
                      const { value, label } = optionsValorPre[index];
                      return (
                        <div key={key} style={style} onClick={() => {
                          handleChange(value, "valorPre");
                          dispatch(populateInput());
                      
                          if (value === "Preço de") {
                            url.searchParams.delete('valorDe');
                          } else {
                            url.searchParams.set('valorDe', value);
                          }
                          url.searchParams.delete('busca');
                          url.searchParams.delete('valorAte');
                          window.history.pushState({}, '', url);
                          const dropdownElement = document.querySelector('.ant-select-dropdown') as HTMLElement | null;
                          if (dropdownElement) {
                            dropdownElement.classList.add('hidden');
                          }
                        }}>
                            <span>{label}</span>
                        </div>
                      );
                    }}
                  />
                )}
              />
                <Select
                defaultValue="Preço até"
                value={inputs.selected.valorPos !== '' ? inputs.selected.valorPos : 'Preço até'}
                onChange={(event) => {
                    handleChange(event, "valorPos")
                    dispatch(populateInput())

                    if(event === "Preço até") {
                        url.searchParams.delete('valorAte');
                    } else {
                        url.searchParams.set('valorAte', event);
                    }
                    url.searchParams.delete('busca');
                    window.history.pushState({}, '', url);
                }}
                options={listaOptionsValorPosReversa}
                suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
                dropdownRender={() => (
                  <List
                    rowCount={listaOptionsValorPosReversa.length}
                    rowHeight={30}
                    width={180}
                    height={Math.min(optionsValorPre.length * 30, 200)}
                    style={{ overflow: 'auto' }}
                    rowRenderer={({ index, key, style }: any) => {
                      const { value, label } = listaOptionsValorPosReversa[index];
                      return (
                        <div key={key} style={style} onClick={() => {
                          handleChange(value, "valorPos");
                          dispatch(populateInput());
                      
                          if(value === "Preço até") {
                            url.searchParams.delete('valorAte');
                          } else {
                              url.searchParams.set('valorAte', value);
                          }
                          url.searchParams.delete('busca');
                          window.history.pushState({}, '', url);
                          const dropdownElement = document.querySelector('.ant-select-dropdown') as HTMLElement | null;
                          if (dropdownElement) {
                            dropdownElement.classList.add('hidden');
                          }
                        }}>
                            <span>{label}</span>
                        </div>
                      );
                    }}
                  />
                )}
                />
              </div>
            ) : (
              <>
                <Select
                defaultValue="Preço de"
                value={inputs.selected.valorPre !== '' ? inputs.selected.valorPre : 'Preço de'}
                onChange={(event) => {
                    handleChange(event, "valorPre")
                    dispatch(populateInput())

                    if(event === "Preço de") {
                        url.searchParams.delete('valorDe');
                    } else {
                        url.searchParams.set('valorDe', event);
                    }
                    url.searchParams.delete('busca');
                    url.searchParams.delete('valorAte');
                    window.history.pushState({}, '', url);
                }}
                options={optionsValorPre}
                suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
                />
                <Select
                defaultValue="Preço até"
                value={inputs.selected.valorPos !== '' ? inputs.selected.valorPos : 'Preço até'}
                onChange={(event) => {
                    handleChange(event, "valorPos")
                    dispatch(populateInput())

                    if(event === "Preço até") {
                        url.searchParams.delete('valorAte');
                    } else {
                        url.searchParams.set('valorAte', event);
                    }
                    url.searchParams.delete('busca');
                    window.history.pushState({}, '', url);
                }}
                options={optionsValorPos}
                suffixIcon={<i className="ri-arrow-down-s-line ri-xl" />}
                />
              </>
            )}
            
            {isMobile ? (
              <div className="mobile-div">
              <S.botaoEstoque>
              <a onClick={() => {
                dispatch(populateInput())
                const novaURL = `/estoque?${parametrosDaURL.toString()}`;
                navigate(novaURL);
              }}>
                  Buscar resultado
              </a>
            </S.botaoEstoque>
            <S.botaoEstoque>
                <a className="icon-button" onClick={() => {
                  setTipoBusca(!tipoBusca)
                }}>
                    <i className="ri-equalizer-line ri-lg"></i>
                </a>
            </S.botaoEstoque>
            </div>
            ) : (
              <>
                <S.botaoEstoque>
                    <a onClick={() => {
                      dispatch(populateInput())
                      const novaURL = `/estoque?${parametrosDaURL.toString()}`;
                      navigate(novaURL);
                    }}>
                        Buscar resultado
                    </a>
                </S.botaoEstoque>
                <S.botaoEstoque>
                    <a className="icon-button" onClick={() => {
                      setTipoBusca(!tipoBusca)
                    }}>
                        <i className="ri-equalizer-line ri-lg"></i>
                    </a>
                </S.botaoEstoque>
              </>
            )}
      </div>
    </S.Container>
  );
};