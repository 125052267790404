import CookieButton from "../../components/Cookies";
import { WhatsappFlutuante } from "../../components/WhatsappFlutuante";
import { Header } from "../../components/Header"
import * as S from "./styles";
import { useDispatch } from 'react-redux';
import { AppDispatch } from "../../App";
import { useEffect } from 'react';
import { getFuncionarios, getInfoLoja } from "../../shared/redux/slices/litoralAdmin";

import Metatags from "../../components/Metatags";
import { InformacoesLoja } from "../../components/InformacoesLoja";
import { Instagram } from "../../components/Instagram";

export const SobreNos = () => {
  const dispatch = useDispatch<AppDispatch>()


  useEffect(() => {
    dispatch(getInfoLoja())
    dispatch(getFuncionarios())
  }, [dispatch])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{minHeight: "calc(100vh - 156px)"}}>
      <S.Container>
        <Metatags title="Muito Mais Carros" description="Muito Mais Carros - O lugar certo para sair de carro novo!" url={window.location.href} img={`${process.env.PUBLIC_URL}/LogoLoja.ico`} />
        <Header titulo="Quem somos" />
        <div className="info">
          <InformacoesLoja depoimentos={false} />
        </div>
        <Instagram />
        <WhatsappFlutuante />
        <CookieButton />
      </S.Container>
    </div>
  );
};
