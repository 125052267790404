import * as S from './styles'
import React, { useEffect, useState } from 'react';
import { Header as HeaderComponent } from '../../../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../App';
import { getSlide} from '../../../shared/redux/slices/litoralLoja';
import { getSlice as getSlideSelector } from '../../../shared/redux/selectors';
import { isMobile } from 'react-device-detect';
import { Busca } from '../Buscar';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

declare global {
  interface HTMLElement {
    getBoundingClientRect: () => DOMRect;
  }
}

const Header: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const banner = useSelector(getSlideSelector)

  useEffect(()=>{
    dispatch(getSlide())
  },[])

  const findBanner = (isMobile: boolean) => {
    const mobile = banner?.filter((x: { isMobile: boolean; }) => x.isMobile === true) 
    const desktop = banner?.filter((x: { isMobile: boolean; }) => x.isMobile === false) 

    return isMobile && mobile?.length > 0 ? mobile : desktop
  }

  const findVideo = (isMobile: boolean) => {
    const findBannerVideo = banner?.filter((x: { tipo: string; }) => x.tipo === "VIDEO")
    const findBanner = findBannerVideo?.find((x: { isMobile: boolean; }) => x.isMobile === isMobile)

    let src = `${process.env.REACT_APP_API_FOTOS_VIDEOS}/videos/${findBanner?.arquivo}`
    if(navigator.platform.includes('Mac')) {
      return src
    }
    if(!findBanner) {
      src = `${process.env.REACT_APP_API_FOTOS_VIDEOS}/videos/${banner?.[0]?.arquivo}`
    }
    return src
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: true,
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
    },
    []
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if(instanceRef.current) {
        instanceRef?.current.next()
      }
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [instanceRef])

  return (
    <S.Container>
      <HeaderComponent titulo='Início' />
            <S.Banner>
              {banner?.[0]?.tipo === "BANNER" ? (
                  <div className="navigation-wrapper">
                  <div ref={sliderRef} className="keen-slider" style={{maxWidth: "99vw", height: '100%'}}>
                    {findBanner(isMobile ? true : false).map((image: any, index: any) => (
                      <div key={index} className="keen-slider__slide" style={{ width: "100vw", height: '100%' }}>
                        <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={`${process.env.REACT_APP_API_FOTOS_VIDEOS}/fotos/${image?.arquivo}`} alt={image.alt} />
                      </div>
                    ))}
                    {loaded && instanceRef.current && findBanner(isMobile ? true : false).length > 1 && (
                      <div style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%'}}>
                        <i style={{left: '10px', position: 'absolute', cursor: 'pointer'}} className="ri-arrow-left-s-line ri-lg" onClick={(e: any) =>
                              e.stopPropagation() || instanceRef.current?.prev()
                            }/>
                        <i style={{right: '10px', position: 'absolute', cursor: 'pointer'}} className="ri-arrow-right-s-line ri-lg" onClick={(e: any) =>
                              e.stopPropagation() || instanceRef.current?.next()
                            }/>
                      </div>
                      )}
                    </div>
                    </div>
                 ) : (
                  <div style={{width: '100%', position: 'relative', overflow: "hidden"}}>
                    <video width="100%" muted autoPlay loop preload="auto" playsInline src={findVideo(isMobile ? true : false)}></video>
                    <div className="gradient-overlay" style={{position: 'absolute',
                      top: '30%',
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))'}}>
                    </div>
                  </div>
                )}
                <Busca />
            </S.Banner>
      </S.Container>
  )
}

export default Header;