import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getEstoque as getEstoqueService, getVeiculo as getVeiculoService } from "../../../service/apiLitoral";
import { Veiculos as VeiculoDictionary } from "../../utils/dictionary";
import { LitoralState, Retorno, Veiculo, Veiculos } from "../../types";

const initialState: LitoralState = {
  inputs: {
    selected: {
      categoria: 'Carro/Camionetas',
      marca: '',
      modelo: '',
      anoPre: '',
      anoPos: '',
      valorPre: '',
      valorPos: '',
      combustivel: [],
      opcionais: []
    },
    data: {
      categoria: [],
      marca: [],
      modelo: [],
      anoPre: [],
      anoPos: [],
      valorPre: [],
      valorPos: [],
      combustivel: [],
      opcionais: []
    }
  },
  veiculosFiltrado: [
    {
      [VeiculoDictionary.CODIGO_VEICULO]: "",
      [VeiculoDictionary.TIPO_CATEGORIA]: "",
      [VeiculoDictionary.CATEGORIA]: "",
      [VeiculoDictionary.MARCA]: "",
      [VeiculoDictionary.MODELO]: "",
      [VeiculoDictionary.VEICULO]: "",
      [VeiculoDictionary.VEICULO2]: "",
      [VeiculoDictionary.VERSAO]: "",
      [VeiculoDictionary.COR]: "",
      [VeiculoDictionary.MOTOR]: "",
      [VeiculoDictionary.ANO]: "",
      [VeiculoDictionary.YOUTUBE]: "",
      [VeiculoDictionary.EM_OFERTA]: 0,
      [VeiculoDictionary.VALOR]: "",
      [VeiculoDictionary.VALAOR_OFERTA]: "",
      [VeiculoDictionary.OBS_SITE]: "",
      [VeiculoDictionary.FOTOS]: [],
      [VeiculoDictionary.KM]: "",
      [VeiculoDictionary.COMBUSTIVEL]: "",
      [VeiculoDictionary.OPCIONAIS]: []
    },
  ],
  estoque: [
    {
      [VeiculoDictionary.CODIGO_VEICULO]: "",
      [VeiculoDictionary.TIPO_CATEGORIA]: "",
      [VeiculoDictionary.CATEGORIA]: "",
      [VeiculoDictionary.MARCA]: "",
      [VeiculoDictionary.MODELO]: "",
      [VeiculoDictionary.VEICULO]: "",
      [VeiculoDictionary.VEICULO2]: "",
      [VeiculoDictionary.VERSAO]: "",
      [VeiculoDictionary.COR]: "",
      [VeiculoDictionary.MOTOR]: "",
      [VeiculoDictionary.ANO]: "",
      [VeiculoDictionary.YOUTUBE]: "",
      [VeiculoDictionary.EM_OFERTA]: 0,
      [VeiculoDictionary.VALOR]: "",
      [VeiculoDictionary.VALAOR_OFERTA]: "",
      [VeiculoDictionary.OBS_SITE]: "",
      [VeiculoDictionary.FOTOS]: [],
      [VeiculoDictionary.KM]: "",
      [VeiculoDictionary.COMBUSTIVEL]: "",
      [VeiculoDictionary.OPCIONAIS]: []
    },
  ],
  veiculo: 
    {
      [VeiculoDictionary.CODIGO_VEICULO]: "",
      [VeiculoDictionary.TIPO_CATEGORIA]: "",
      [VeiculoDictionary.CATEGORIA]: "",
      [VeiculoDictionary.MARCA]: "",
      [VeiculoDictionary.MODELO]: "",
      [VeiculoDictionary.VEICULO]: "",
      [VeiculoDictionary.VEICULO2]: "",
      [VeiculoDictionary.VERSAO]: "",
      [VeiculoDictionary.COR]: "",
      [VeiculoDictionary.MOTOR]: "",
      [VeiculoDictionary.ANO]: "",
      [VeiculoDictionary.YOUTUBE]: "",
      [VeiculoDictionary.EM_OFERTA]: 0,
      [VeiculoDictionary.VALOR]: "",
      [VeiculoDictionary.VALAOR_OFERTA]: "",
      [VeiculoDictionary.OBS_SITE]: "",
      [VeiculoDictionary.FOTOS]: [],
      [VeiculoDictionary.KM]: "",
      [VeiculoDictionary.COMBUSTIVEL]: "",
      [VeiculoDictionary.FINAL_PLACA]: "",
      [VeiculoDictionary.OPCIONAIS]: []
    }
};

const litoralSlice = createSlice({
  name: "litoralSlice",
  initialState,
  reducers: {
    resetInput: (state:LitoralState, action: PayloadAction<{tipo: 'categoria'| 'marca' | 'modelo' | 'anoPre' | 'anoPos' | 'valorPre' | 'valorPos' | 'combustivel' | 'opcionais'}>) => {
      if(action.payload.tipo === 'combustivel' || action.payload.tipo === 'opcionais') {
        state.inputs.selected[action.payload.tipo] = []
      } else {
        state.inputs.selected[action.payload.tipo] = ''
      }
    },
    populateInput: (state:LitoralState) => {
      // Popula inputs de acordo com estoque
      const categorias = state.estoque.map((x) => {
        return x.categoria
      })
      state.inputs.data.categoria = categorias.filter((element, index) => {
        return categorias.indexOf(element) === index;
      });
      state.inputs.data.marca.unshift("Categoria")

      const filtroMarca = state.inputs.selected.categoria === "" ? state.estoque : state.estoque.filter(x => x.categoria === state.inputs.selected.categoria)
      const marca = filtroMarca.map((x) => {
        return x.marca
      })
      state.inputs.data.marca = marca.filter((element, index) => {
        return marca.indexOf(element) === index;
      });
      state.inputs.data.marca.unshift("Marca")

      const filtroModelo = state.estoque.filter(item => {
        const categoriaMatch = state.inputs.selected.categoria === '' || item.categoria === state.inputs.selected.categoria;
        const marcaMatch = state.inputs.selected.marca === '' || item.marca === state.inputs.selected.marca;
        
        return categoriaMatch && marcaMatch;
      });
      const modelo = filtroModelo.map((x) => {
        return x.modelo
      })
      state.inputs.data.modelo = modelo.filter((element, index) => {
        return modelo.indexOf(element) === index;
      });
      state.inputs.data.modelo.unshift("Modelo")

      const filtroAno = state.inputs.selected.modelo === "" ? filtroModelo : filtroModelo.filter(x => x.modelo === state.inputs.selected.modelo)
      const ano = filtroAno.map((x) => {
        return x.ano
      })
      state.inputs.data.anoPre = ano.filter((element, index) => {
        return ano.indexOf(element) === index;
      }).sort((a,b) => Number(a) - Number(b));
      state.inputs.data.anoPos = ano.filter((element, index) => {
        return ano.indexOf(element) === index;
      }).sort((a,b) => Number(a) - Number(b));
      state.inputs.data.anoPre.unshift("Ano de")
      state.inputs.data.anoPos.push("Ano ate")

      let filtroValor = filtroAno;
          if(state.inputs.selected.anoPre === '' && state.inputs.selected.anoPos === '') {
            filtroValor = filtroAno
          }
          if(state.inputs.selected.anoPre !== '') {
            filtroValor = filtroAno.filter(x => x.ano >= state.inputs.selected.anoPre)
          }
          if(state.inputs.selected.anoPos !== '') {
            filtroValor = filtroAno.filter(x => x.ano <= state.inputs.selected.anoPos)
          }
          if(state.inputs.selected.anoPos !== '' && state.inputs.selected.anoPre !== '') {
            filtroValor = filtroAno.filter(x => x.ano >= state.inputs.selected.anoPre).filter(y => y.ano <= state.inputs.selected.anoPos)
          }

      const valor = filtroValor.map((x) => {
        return x.valor
      })
      const filtroValorAte = state.estoque.filter(item => {
        const categoriaMatch = state.inputs.selected.categoria === '' || item.categoria === state.inputs.selected.categoria;
        const marcaMatch = state.inputs.selected.marca === '' || item.marca === state.inputs.selected.marca;
        const modeloMatch = state.inputs.selected.modelo === '' || item.modelo === state.inputs.selected.modelo;
        const valorDeMatch = state.inputs.selected.valorPre === '' || Number(state.inputs.selected.valorPre) <= Number(item.valor);
        
        return categoriaMatch && marcaMatch && modeloMatch && valorDeMatch;
      });
      const valorAte = filtroValorAte.map((x) => {
        return x.valor.toString()
    })
      state.inputs.data.valorPre = valor.filter((element, index) => {
        return valor.indexOf(element) === index;
      }).sort((a,b) => Number(a) - Number(b));
      state.inputs.data.valorPos = valorAte.filter((element, index) => {
        return valorAte.indexOf(element) === index;
      }).sort((a,b) => Number(a) - Number(b));
      state.inputs.data.valorPre.unshift("Valor de")
      state.inputs.data.valorPos.push("Valor ate")
    },
    setInputSelected: (state: LitoralState, action: PayloadAction<{index: string, checkbox?: boolean, tipo: 'categoria'| 'marca' | 'modelo' | 'anoPre' | 'anoPos' | 'valorPre' | 'valorPos' | 'combustivel' | 'opcionais'}>) => {
      if(action.payload.tipo === 'combustivel' || action.payload.tipo === "opcionais") {
        if(action.payload.checkbox) {
          state.inputs.selected[action.payload.tipo].push(action.payload.index)
        } else {
          state.inputs.selected[action.payload.tipo] = state.inputs.selected[action.payload.tipo].filter(x => x !== action.payload.index)
        }
      } else {
        if(action.payload.index === 'Categoria' || action.payload.index === 'Marca' || action.payload.index === 'Modelo' || action.payload.index === 'Ano de' || action.payload.index === 'Ano ate' || action.payload.index === 'Valor de' || action.payload.index === 'Valor ate') {
          state.inputs.selected[action.payload.tipo] = ''
        } else {
          state.inputs.selected[action.payload.tipo] = action.payload.index
        }
      }
    },
    filterEstoque: (state: LitoralState) => {
      state.veiculosFiltrado = state.estoque.sort((objA, objB) => Number(objB.valor) - Number(objA.valor))
      if(state.inputs.selected.categoria !== '') {
        state.veiculosFiltrado = state.estoque.filter(x => x.categoria === state.inputs.selected.categoria)
      }
      if(state.inputs.selected.marca !== '') {
        state.veiculosFiltrado = state.veiculosFiltrado.filter(x => x.marca === state.inputs.selected.marca)
      }
      if(state.inputs.selected.modelo !== '') {
        state.veiculosFiltrado = state.veiculosFiltrado.filter(x => x.modelo === state.inputs.selected.modelo)
      }
      if(state.inputs.selected.anoPre !== '') {
        state.veiculosFiltrado = state.veiculosFiltrado.filter(x => x.ano >= state.inputs.selected.anoPre)
      }
      if(state.inputs.selected.anoPos !== '') {
        state.veiculosFiltrado = state.veiculosFiltrado.filter(x => x.ano <= state.inputs.selected.anoPos)
      }
      if(state.inputs.selected.valorPre !== '') {
        state.veiculosFiltrado = state.veiculosFiltrado.filter(x => x.valor >= state.inputs.selected.valorPre)
      }
      if(state.inputs.selected.valorPos !== '') {
        state.veiculosFiltrado = state.veiculosFiltrado.filter(x => x.valor <= state.inputs.selected.valorPos)
      }
      if(state.inputs.selected.combustivel.length !== 0) {
        state.veiculosFiltrado = state.veiculosFiltrado.filter((el) => {
          return state.inputs.selected.combustivel.some((f) => {
            return f === el.combustivel && f === el.combustivel;
          });
        });
      }
      if(state.inputs.selected.opcionais.length !== 0) {
        state.veiculosFiltrado = state.veiculosFiltrado.filter(obj =>
          state.inputs.selected.opcionais
            .some(termoParcial => obj.opcionais.map((x) => {
              return x.descricao
            }).includes(termoParcial))
        );
      }
    },
    filterEstoqueByBusca: (state: LitoralState, action: PayloadAction<string>) => {
      const normalizeString = (str: string) => {
        return str
          .toLocaleLowerCase()                    // Converte para minúsculas
          .normalize("NFD")                       // Normaliza a string para decompor os caracteres acentuados
          .replace(/[\u0300-\u036f]/g, '')        // Remove os acentos
          .replace(/[^a-z0-9]/g, '');             // Remove espaços e caracteres que não sejam letras ou números
      };
      const filterByMarca = state.veiculosFiltrado.filter(x => 
        normalizeString(x.marca).includes(normalizeString(action.payload))
      );
      const filterByModelo = state.veiculosFiltrado.filter(x => 
        normalizeString(x.modelo).includes(normalizeString(action.payload))
      );
      if(filterByModelo.length === 0) {
        state.veiculosFiltrado = filterByMarca
      } else {
        state.veiculosFiltrado = filterByModelo
      }
    },
    resetCampo: (state: LitoralState, action:  PayloadAction<{nome: 'categoria'| 'marca' | 'modelo' | 'anoPre' | 'anoPos' | 'valorPre' | 'valorPos' | 'combustivel' | 'opcionais'}>) => {
        const listaInputs = ['categoria', 'marca', 'modelo', 'anoPre', 'anoPos', 'valorPre', 'valorPos', 'combustivel', 'opcionais']
        listaInputs.filter(x => x !== action.payload.nome)
        listaInputs.forEach(element => {
          if(element === 'combustivel' || element === 'opcionais') {
            state.inputs.selected[element] = []
          } else if (element === 'categoria' || element === 'marca' || element === 'modelo' || element === 'anoPre' || element === 'anoPos' || element === 'valorPre' || element === 'valorPos') {
            state.inputs.selected[element] = ''
          }
        });
    },
    sortEstoque: (state: LitoralState, action: PayloadAction<string>) => {
      switch (action.payload) {
        case 'nome': state.veiculosFiltrado = state.veiculosFiltrado.slice().sort((a,b) => a.marca.localeCompare(b.marca))
          break;
        case 'maiorAno': state.veiculosFiltrado = state.veiculosFiltrado.slice().sort((a,b) => Number(b.ano) - Number(a.ano))
          break;
        case 'menorAno': state.veiculosFiltrado = state.veiculosFiltrado.slice().sort((a,b) => Number(a.ano) - Number(b.ano))
          break;
        case 'maiorValor': state.veiculosFiltrado = state.veiculosFiltrado.slice().sort((a,b) => Number(b.valor) - Number(a.valor))
          break;
        case 'menorValor': state.veiculosFiltrado = state.veiculosFiltrado.slice().sort((a,b) => Number(a.valor) - Number(b.valor))
          break;
        default: state.veiculosFiltrado = state.veiculosFiltrado
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEstoque.fulfilled,
      (state, action: PayloadAction<Retorno<Veiculo[]>>) => {
        state.estoque = action.payload.retorno;
        state.veiculosFiltrado = action.payload.retorno;

        // Popula inputs de acordo com estoque
        const categorias = state.veiculosFiltrado.map((x) => {
          return x.categoria
        })
        state.inputs.data.categoria = categorias.filter((element, index) => {
          return categorias.indexOf(element) === index;
        });

        const marca = state.veiculosFiltrado.map((x) => {
          return x.marca
        })
        state.inputs.data.marca = marca.filter((element, index) => {
          return marca.indexOf(element) === index;
        });
        state.inputs.data.marca.unshift("Marca")

        const modelo = state.veiculosFiltrado.map((x) => {
          return x.modelo
        })
        state.inputs.data.modelo = modelo.filter((element, index) => {
          return modelo.indexOf(element) === index;
        });
        state.inputs.data.modelo.unshift("Modelo")

        const ano = state.veiculosFiltrado.map((x) => {
          return x.ano
        })
        state.inputs.data.anoPre = ano.filter((element, index) => {
          return ano.indexOf(element) === index;
        }).sort((a,b) => Number(a) - Number(b));
        state.inputs.data.anoPos = ano.filter((element, index) => {
          return ano.indexOf(element) === index;
        }).sort((a,b) => Number(a) - Number(b));
        state.inputs.data.anoPre.unshift("Ano de")
        state.inputs.data.anoPos.push("Ano ate")

        const valor = state.veiculosFiltrado.map((x) => {
          return x.valor
        })
        state.inputs.data.valorPre = valor.filter((element, index) => {
          return valor.indexOf(element) === index;
        }).sort((a,b) => Number(a) - Number(b));
        state.inputs.data.valorPos = valor.filter((element, index) => {
          return valor.indexOf(element) === index;
        }).sort((a,b)=>Number(a)-Number(b)) ;
        state.inputs.data.valorPre.unshift("Valor de")
        state.inputs.data.valorPos.push("Valor ate")

        const combustivel = state.estoque.map((x) => {          
          return x.combustivel
        })
        state.inputs.data.combustivel = combustivel.filter((element, index) => {
          return combustivel.indexOf(element) === index;
        })

        // Exclusivo para quando vier do select dropdown da Home
        if(state.inputs.selected.marca !== "") {
          state.veiculosFiltrado = state.veiculosFiltrado.filter(x => x.marca === state.inputs.selected.marca)
        }

        const opcionais = state.estoque.map((x) => {          
          return x.opcionais.map((y) => {
            return y.descricao
          })
        })
        const newOpcionais = opcionais.reduce((acc, cur) => acc.concat(cur), []);
        state.inputs.data.opcionais = newOpcionais.filter((element, index) => {
          return newOpcionais.indexOf(element) === index;
        })

        state.estoque = state.estoque
      }
    );
    builder.addCase(
      getVeiculo.fulfilled,
      (state, action: PayloadAction<Retorno<Veiculo>>) => {
        state.veiculo = action.payload.retorno
      }
    );
  },
});

export const getEstoque = createAsyncThunk<Retorno<Veiculo[]>, void>(
  "estoque/getEstoque",
  async () => {
    const response = await getEstoqueService();
    return response?.data;
  }
);

export const getVeiculo = createAsyncThunk<Retorno<Veiculo>, string>(
  "veiculo/getVeiculo",
  async (codVeiculo) => {
    const response = await getVeiculoService(codVeiculo);    
    return response?.data;
  }
);

export const { setInputSelected, filterEstoque, resetCampo, sortEstoque, resetInput, populateInput, filterEstoqueByBusca } = litoralSlice.actions;
export default litoralSlice.reducer;
