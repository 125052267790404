import { AiOutlineMail } from 'react-icons/ai'
import { AsideAdmin } from '../../../components/AsideAdmin'
import { HeaderAdmin } from '../../../components/HeaderAdmin'
import * as S from './styles'
import { Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../App'
import { useEffect, useState } from 'react'
import { getEmailsAdmin } from '../../../shared/redux/selectors'
import { getEmailsAvaliacao, getEmailsContato, getEmailsNewsLetter, getEmailsProposta } from '../../../shared/redux/slices/litoralAdmin'

export const Emails = () => {

    const dispatch = useDispatch<AppDispatch>()
    const emails = useSelector(getEmailsAdmin)
    const [listFilter, setListFilter] = useState<any>()

    useEffect(()=>{
        dispatch(getEmailsProposta())
        dispatch(getEmailsAvaliacao())
        dispatch(getEmailsContato())
        dispatch(getEmailsNewsLetter())
    },[])

    useEffect(() => {
        setListFilter(emails.contato?.slice().reverse())
    },[emails])

    const columnsNewsLetter = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Data de cadastro',
        dataIndex: 'dataCadastro',
        key: 'dataCadastro',
      },
    ];

      const columns = [
        {
          title: 'Nome',
          dataIndex: 'nome',
          key: 'nome',
        },
        {
          title: 'Telefone',
          dataIndex: 'telefone',
          key: 'telefone',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Cidade',
          dataIndex: 'cidade',
          key: 'cidade',
        },
        {
          title: 'Data',
          dataIndex: 'data_hora',
          key: 'data_hora',
          render: (text: string) => {
            return text.substring(0, 10)
          }
        },
        {
          title: 'Mensagem',
          dataIndex: 'mensagem',
          key: 'mensagem',
        },
      ];

      const handleChangeMes = (value: 'contato' | 'avaliacao' | 'proposta') => {
        setListFilter(emails[value])
      };
   
    return (
        <S.Container>
            <AsideAdmin />
            <HeaderAdmin />
            <div className='title'>
                <AiOutlineMail size={40} />
                <h1>Emails</h1>
            </div>
            <h4>Filtrar registros pela categoria de email: {listFilter?.length}</h4>
            <div style={{display: "flex", flexDirection: "row", gap: 20}}>
                <Select
                    defaultValue="contato"
                    style={{ width: 300 }}
                    onChange={handleChangeMes}
                    options={[
                        { value: 'contato', label: 'Contato' },
                        { value: 'avaliacao', label: 'Avaliação' },
                        { value: 'proposta', label: 'Proposta' },
                    ]}
                />
            </div>
            <Table dataSource={listFilter} columns={columns} />
            <div className='title'>
                <AiOutlineMail size={40} />
                <h1>Emails de news letter</h1>
                <Table dataSource={emails.newsLetter} columns={columnsNewsLetter} />
            </div>
        </S.Container>
    )
}