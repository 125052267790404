
import * as S from './styles'
import {getEstoqueFiltrado, getLoja as getLojaSelectors } from '../../../shared/redux/selectors';
import { useSelector } from 'react-redux';
import { Loja as LojaDictionary } from '../../../shared/utils/dictionary';
import CardVeiculo from '../../../components/CardVeiculo';
import { Filtro } from '../Filtro';
import { useEffect, useState } from 'react';

export const EstoqueConteudo = () => {
    const loja = useSelector(getLojaSelectors)
    const veiculos = useSelector(getEstoqueFiltrado)
    const codLoja = loja?.[LojaDictionary.CODIGO_LOJA]
    const [sliceEstoque, setSliceEstoque] = useState(12)

    function handleScroll() {
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const clientHeight = document.documentElement.clientHeight;

        const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;

        if (scrollPercent >= 75) {
        setSliceEstoque(sliceEstoque + 12)
        }
    }

    window.addEventListener('scroll', handleScroll);

    return (
        <S.Container>
            <h2>CONHEÇA NOSSO ESTOQUE COMPLETO</h2>
            <div className='hr' />
            <Filtro />
            <S.Cards>
                {veiculos.slice(0, sliceEstoque).map((veiculo) => {
                    return <CardVeiculo key={veiculo.cod_veiculo} codLoja={codLoja} veiculo={veiculo} />
                })}
            </S.Cards>
        </S.Container>
    )
}