import styled from "styled-components";

export const Container = styled.div`

    gap: 20px;
    width: 100%;
    margin: auto;
    padding: 0 48px 0 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    border-radius: 0 15px 15px 0;

    iframe {
        height: 500px;
    }
    @media (max-width: 991px) {
        padding: 0 10px 25px 10px;
        background: none;
        iframe {
            height: 300px;
        }
    }
    `
export const DivIframe = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    justify-content: space-around;
`