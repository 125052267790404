import * as S from "./styles";
import logoLoja from '../../assets/images/LogoLoja.png'
import { useSelector } from "react-redux";
import { getInfoLoja } from "../../shared/redux/selectors";
import { isMobile } from "react-device-detect";
import pcInsta from '../../assets/images/iphoneInsta.png'
import celInsta from '../../assets/images/iphoneInsta.png'
import { useEffect, useState } from "react";
import axios from "axios";

export const Instagram = () => {
  const infoLoja = useSelector(getInfoLoja)

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Função assíncrona para buscar os posts do Instagram
    const fetchInstagramPosts = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url,thumbnail_url&access_token=${process.env.REACT_APP_TOKEN_INSTA}&limit=6`
        );

        setPosts(response.data.data);
      } catch (error) {
        console.error('Erro ao buscar os posts do Instagram:', error);
      }
    };

    fetchInstagramPosts();
  }, []);

  return (
    <>
    {isMobile ? (
      <S.ContainerMobile>
        <div className="row">
          <img src={logoLoja} alt="" />
          <span>
              <h2>SIGA-NOS NO INSTAGRAM</h2>
              <div className="hr" />
            </span>
        </div>
        {/* TODO mudar img com o gui */}
        <a target="_blank" href={infoLoja.urlInstagram} rel="noreferrer"><img style={{width: "300px", maxWidth: "none"}} src={celInsta} alt="" /></a>
      </S.ContainerMobile>
    ) : (
      <S.Container>
        <a target="_blank" href={infoLoja.urlInstagram} rel="noreferrer"><img src={pcInsta} alt="" /></a>
        <div className="container">
          <div>
            <img src={logoLoja} alt="" />
            <span>
              <h2>SIGA-NOS NO INSTAGRAM</h2>
              <div className="hr" />
            </span>
          <div>
        </div>
          </div>
          {isMobile ? "" : (
            <div className="insta-grid" onClick={() => {
              window.open(infoLoja.urlInstagram, "_blank")
            }}>
              {posts.map((x : {thumbnail_url: string, media_url: string, caption: string, id: number}) => (
                <div key={x.id} className="container-img">
                  <img src={x.thumbnail_url ? x.thumbnail_url : x.media_url} alt={x.caption} />
                </div>
              ))}
            </div>
          )}
        </div>
      </S.Container>
    )}
    </>
  );
};
