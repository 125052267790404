import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Veiculo as VeiculosTypes } from '../../../../shared/types';
import { EnvioEmailProposta as EnvioEmailPropostaDictionary, Veiculos as VeiculosDictionary } from '../../../../shared/utils/dictionary';
import { postEmailProposta } from '../../../../service/apiLitoral';
import InputMask from 'react-input-mask';
import * as S from './styles'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getLoja } from '../../../../shared/redux/selectors';

type EmailPropostaType = {
    [EnvioEmailPropostaDictionary.CIDADE]: string;
    [EnvioEmailPropostaDictionary.EMAIL]: string;
    [EnvioEmailPropostaDictionary.MENSAGEM]: string;
    [EnvioEmailPropostaDictionary.NOME]: string;
    [EnvioEmailPropostaDictionary.TELEFONE]: string;
    [EnvioEmailPropostaDictionary.UF]: string;
    [EnvioEmailPropostaDictionary.VEICULO]: string;
  }

const MyFormItemContext = React.createContext<(string | number)[]>([]);

interface MyFormItemGroupProps {
    prefix: string | number | (string | number)[];
    children: React.ReactNode;
}

function toArr(str: string | number | (string | number)[]): (string | number)[] {
    return Array.isArray(str) ? str : [str];
}

const MyFormItemGroup = ({ prefix, children }: MyFormItemGroupProps) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);

    return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
};

type VeiculoFurmTypes = {
    veiculo: VeiculosTypes,
    setOpen?: (args: boolean) => void,
}

export const FormProposta = ({veiculo, setOpen} : VeiculoFurmTypes) => {
    const [form] = Form.useForm();
    const [telefone, setTelefone] = useState('');
    const loja = useSelector(getLoja)
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams);
    const link = window.location.origin + window.location.pathname + window.location.search;
    
    const onFinish = (value: EmailPropostaType) => {
        const data = {
            [EnvioEmailPropostaDictionary.CIDADE]: value.cidade,
            [EnvioEmailPropostaDictionary.EMAIL]: value.email,
            [EnvioEmailPropostaDictionary.MENSAGEM]: value.mensagem ? value.mensagem : `Olá, tenho interesse nesse: ${veiculo.marca} ${veiculo.modelo} - ${veiculo.ano} - no valor de ${Number(veiculo.em_oferta === 0 ? veiculo.valor : veiculo.valor_oferta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
            [EnvioEmailPropostaDictionary.NOME]: value.nome,
            [EnvioEmailPropostaDictionary.TELEFONE]: value.telefone,
            [EnvioEmailPropostaDictionary.UF]: value.uf,
            [EnvioEmailPropostaDictionary.VEICULO]: params.codVeiculo,
            [EnvioEmailPropostaDictionary.EMAIL_LOJA]: loja.emails ? loja.emails[0].email : 'admmuitomaiscarros@gmail.com',
        }
        postEmailProposta(data)
        toast.success("Email enviado com sucesso!")
        form.resetFields();
        if(setOpen) {
            setOpen(false)
        }
    };
    
    return (
        <S.Container>
            <Form 
                form={form}
                name="proposta"
                layout="vertical"
                initialValues={{
                    veiculo: `Olá, tenho interesse nesse: ${veiculo.marca} ${veiculo.modelo} - ${veiculo.ano} - no valor de ${Number(veiculo.em_oferta === 0 ? veiculo.valor : veiculo.valor_oferta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                }}
                onFinish={onFinish}
            >
                <MyFormItemGroup prefix={['proposta']}>
                    
                {veiculo?.[VeiculosDictionary.EM_OFERTA] === 0 ? (
                    <h2>{Number(veiculo[VeiculosDictionary.VALOR]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h2>
                ) : (
                    <div>
                        <h3 style={{textDecoration: "line-through", marginBottom: 0}}>{Number(veiculo[VeiculosDictionary.VALOR]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h3>
                        <h2>{Number(veiculo[VeiculosDictionary.VALAOR_OFERTA]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h2>
                    </div>
                )}
                <hr />
                <h3>Deixe sua mensagem caso tenha interesse</h3>

                    <Form.Item rules={[{ required: true, message: 'Insira seu nome!' }]} name="nome">
                        <Input placeholder='Nome' />
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: 'Insira o seu email!' }]} name="email">
                        <Input placeholder='Email' />
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: 'Insiraseu telefone!' }]} name="telefone">
                        <InputMask
                            placeholder='Telefone'
                            mask="(99) 99999-9999"
                            value={telefone}
                            onChange={(e) => setTelefone(e.target.value)}
                            />
                    </Form.Item>
                    <Form.Item name="mensagem">
                        <Input.TextArea placeholder={`Olá, tenho interesse nesse: ${veiculo.marca} ${veiculo.modelo} - ${veiculo.ano} - no valor de ${Number(veiculo.em_oferta === 0 ? veiculo.valor : veiculo.valor_oferta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`} style={{resize: "none", height: "100px"}} showCount maxLength={100} />
                    </Form.Item>
                </MyFormItemGroup>

                <Button className='envio' type="primary" htmlType="submit">
                    Enviar mensagem
                </Button>
                <hr />
                <Button onClick={() => {
                    window.open(`https://api.whatsapp.com/send?phone=+55${loja.telefones ? loja.telefones[0].telefone.replace(/[( )-]/g,"") : "48 98871-3703".replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site da *${loja?.nome_empresa}* e gostaria de mais informações sobre ${encodeURIComponent(link)}.`, '_blank')
                }} className='whats' type="primary" htmlType="button">
                    <span><i className="ri-whatsapp-line ri-lg"></i> Chame a gente no Whatsapp</span>
                </Button>
            </Form>
        </S.Container>
    );
};

