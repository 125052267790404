import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInfosLoja as getInfosLojaService, getBanner as getBannerService, getVideo as getVideoService, getFuncionarios as getFuncionariosService, buscarVisitasDias, buscarVisitasMeses, buscarVisitasAnos, getWhatsApp, getEmailsContato as getEmailsContatoService, getEmailsAvaliacao as getEmailsAvaliacaoService, getEmailsProposta as getEmailsPropostaSevice, getNewsLetter as getEmailsNewsLetterService } from "../../../service/apiLitoral";
import { LitoralAdminState, InfosLoja, Banner, Video, Funcionarios, AcessosBody, WhatsBody, EmailAdminBody, EmailsAdminNewsLetter, Acessos } from "../../types";
import { Banner as BannerDictionary, InfosLoja as InfosLojaDictionary, Whats } from "../../utils/dictionary";

const initialState: LitoralAdminState = {
  emails: {
    contato: [],
    posVenda: [],
    avaliacao: [],
    proposta: [],
    newsLetter: [],
  },
    infoLoja: {
      [InfosLojaDictionary.COD_LOJA]: 0,
      [InfosLojaDictionary.DESCRICAO]: '',
      [InfosLojaDictionary.HORAIRO_DOM]: '',
      [InfosLojaDictionary.HORAIRO_SAB]: '',
      [InfosLojaDictionary.HORAIRO_SEG_SEX]: '',
      [InfosLojaDictionary.URL_FACEBOOK]: '',
      [InfosLojaDictionary.URL_INSTAGRAM]: ''
    },
    banner: [{
      [BannerDictionary.COD_LOJA]: 0,
      [BannerDictionary.ID]: 0,
      [BannerDictionary.TITULO]: '',
      [BannerDictionary.URL]: '',
      [BannerDictionary.SITUACAO]: '',
      [BannerDictionary.POSICAO]: '',
      [BannerDictionary.DATA_INICIO]: '',
      [BannerDictionary.DATA_FIM]: '',
      [BannerDictionary.DATA_HORA]: '',
      [BannerDictionary.ARQUIVO]: '',
      [BannerDictionary.MOBILE]: false,
    }],
    video: [{
      [BannerDictionary.COD_LOJA]: 0,
      [BannerDictionary.ID]: 0,
      [BannerDictionary.TITULO]: '',
      [BannerDictionary.URL]: '',
      [BannerDictionary.SITUACAO]: '',
      [BannerDictionary.POSICAO]: '',
      [BannerDictionary.DATA_INICIO]: '',
      [BannerDictionary.DATA_FIM]: '',
      [BannerDictionary.DATA_HORA]: '',
      [BannerDictionary.ARQUIVO]: '',
      [BannerDictionary.MOBILE]: false,
    }],
    funcionarios: [],
    acessos: {
      dia: [{
        dia: '',
        acessos: 0
      }],
      mes: [{
        dia: '',
        acessos: 0
      }],
      ano: [{
        dia: '',
        acessos: 0
      }]
    },
    whats: [{
      [Whats.DATA]: "",
      [Whats.IP]: "",
    }]
};

const litoralAdminSlice = createSlice({
  name: "litoralAdminSlice",
  initialState,
  reducers: {
    resetState: (state:LitoralAdminState) => {
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
        getInfoLoja.fulfilled,
        (state, action: PayloadAction<InfosLoja>) => {
          state.infoLoja = action.payload.loja.tbl_infosLoja[0];
        }
    );
    builder.addCase(
      getBanner.fulfilled,
        (state, action: PayloadAction<Banner>) => {
          state.banner = action.payload.banner;
        }
    );
    builder.addCase(
      getVideo.fulfilled,
        (state, action: PayloadAction<Video>) => {
          state.video = action.payload.video;
        }
    );
    builder.addCase(
      getFuncionarios.fulfilled,
        (state, action: PayloadAction<Funcionarios>) => {
          state.funcionarios = action.payload.funcionario;
        }
    );
    builder.addCase(
      getAcessosDias.fulfilled,
        (state, action: PayloadAction<Acessos[]>) => {
          state.acessos.dia = action.payload;
        }
    );
    builder.addCase(
      getAcessosMeses.fulfilled,
        (state, action: PayloadAction<Acessos[]>) => {
          state.acessos.mes = action.payload;
        }
    );
    builder.addCase(
      getAcessosAnos.fulfilled,
        (state, action: PayloadAction<Acessos[]>) => {
          state.acessos.ano = action.payload;
        }
    );
    builder.addCase(
      getWhats.fulfilled,
        (state, action: PayloadAction<WhatsBody>) => {
          state.whats = action.payload.whatsappCliques;
        }
    );
    builder.addCase(
      getEmailsContato.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.emails.contato = action.payload.emailContato;
        }
    );
    builder.addCase(
      getEmailsAvaliacao.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.emails.avaliacao = action.payload.avaliacao;
        }
    );
    builder.addCase(
      getEmailsProposta.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.emails.proposta = action.payload.proposta;
        }
    );
    builder.addCase(
      getEmailsNewsLetter.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.emails.newsLetter = action.payload;
        }
    );
  },
});


export const getInfoLoja = createAsyncThunk<InfosLoja, void>(
  "loja/getInfoLoja",
  async () => {
    const response = await getInfosLojaService();
    return response?.data; 
  }
);

export const getBanner = createAsyncThunk<Banner, void>(
  "loja/getBanner",
  async () => {
    const response = await getBannerService();
    return response?.data; 
  }
);
export const getVideo = createAsyncThunk<Video, void>(
  "loja/getVideo",
  async () => {
    const response = await getVideoService();
    return response?.data; 
  }
);
export const getFuncionarios = createAsyncThunk<Funcionarios, void>(
  "loja/getFuncionarios",
  async () => {
    const response = await getFuncionariosService();
    return response?.data; 
  }
);
export const getAcessosDias = createAsyncThunk<Acessos[], void>(
  "loja/getAcessosDias",
  async () => {
    const response = await buscarVisitasDias();
    return response?.data; 
  }
);
export const getAcessosMeses = createAsyncThunk<Acessos[], void>(
  "loja/getAcessosMeses",
  async () => {
    const response = await buscarVisitasMeses();
    return response?.data; 
  }
);
export const getAcessosAnos = createAsyncThunk<Acessos[], void>(
  "loja/getAcessosAnos",
  async () => {
    const response = await buscarVisitasAnos();
    return response?.data; 
  }
);
export const getWhats = createAsyncThunk<WhatsBody, void>(
  "loja/getWhats",
  async () => {
    const response = await getWhatsApp();
    return response?.data; 
  }
);
export const getEmailsContato = createAsyncThunk<EmailAdminBody, void>(
  "loja/getEmailsContato",
  async () => {
    const response = await getEmailsContatoService();
    return response?.data; 
  }
);
export const getEmailsAvaliacao = createAsyncThunk<EmailAdminBody, void>(
  "loja/getEmailsAvaliacao",
  async () => {
    const response = await getEmailsAvaliacaoService();
    return response?.data; 
  }
);
export const getEmailsProposta = createAsyncThunk<EmailAdminBody, void>(
  "loja/getEmailsProposta",
  async () => {
    const response = await getEmailsPropostaSevice();
    return response?.data; 
  }
);
export const getEmailsNewsLetter = createAsyncThunk<{newsletter: EmailsAdminNewsLetter[]}, void>(
  "loja/getEmailsNewsLetter",
  async () => {
    const response = await getEmailsNewsLetterService();
    return response?.data; 
  }
);

export const { resetState } = litoralAdminSlice.actions;
export default litoralAdminSlice.reducer;
