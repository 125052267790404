/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
import { useSelector } from 'react-redux'
import * as S from './styles'
import { getLoja as getLojaSelector } from '../../../../shared/redux/selectors';
import telefoneImg from '../../../../assets/images/Contatos/telefone.png'
import whatsappImg from '../../../../assets/images/Contatos/whatsapp.png'
import emailImg from '../../../../assets/images/Contatos/email.png'

export const CardsContato = () => {
    
    const loja = useSelector(getLojaSelector)
    return (
        <S.Container>
            {/* Telefone */}
            <S.CardsInfos>
            {loja?.telefones ? loja.telefones.map((telefone) => {
                    if(telefone?.whatsapp === '0') {
                        return (
                            <a key={telefone.telefone} style={{textDecoration: "none", color: "white"}} href={`tel:${telefone}`}>
                                <S.ImgsCardsInfos>
                                    <img src={telefoneImg} width={60} alt="Telefone" />
                                    <div className='text'>
                                        <span><b>TELEFONE</b></span>
                                        <span>{telefone.telefone}</span>
                                    </div>
                                </S.ImgsCardsInfos>
                                </a>
                            )
                    }
                    }) : (
                            <a style={{textDecoration: "none", color: "white"}} href={`tel:(48) 3372-0000`}>
                                <S.ImgsCardsInfos>
                                    <img src={telefoneImg} width={60} alt="Telefone" />
                                    <div className='text'>
                                        <span><b>Telefone</b></span>
                                        <span>(48) 3372-0000</span>
                                    </div>
                                </S.ImgsCardsInfos>
                                </a>
                    )}
            </S.CardsInfos>

            {/* Whatsapp */}
            <S.CardsInfos>
                {loja?.telefones ? loja.telefones.map((telefone) => {
                    if(telefone.whatsapp === '1') {
                        return (
                            <a key={telefone.telefone} style={{textDecoration: "none", color: "white"}} href={`https://api.whatsapp.com/send?phone=+55${telefone.telefone.replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site da *${loja?.nome_empresa}* e gostaria de mais informações.`} target="_blank">
                                <S.ImgsCardsInfos>
                                    <img src={whatsappImg} width={60} alt="Whatsapp" />
                                    <div className='text'>
                                        <span><b>WHATSAPP</b></span>
                                        <span>{telefone.telefone}</span>
                                    </div>
                                </S.ImgsCardsInfos>
                                </a>
                            )
                    }
                    }) : (
                        <a style={{textDecoration: "none", color: "white"}} href={`https://api.whatsapp.com/send?phone=+55${"(48) 98871-3703".replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site da *${loja?.nome_empresa}* e gostaria de mais informações.`} target="_blank">
                                <S.ImgsCardsInfos>
                                    <img src={whatsappImg} width={60} alt="Whatsapp" />
                                    <div className='text'>
                                        <span><b>Whatsapp</b></span>
                                        <span>(48) 98871-3703</span>
                                    </div>
                                </S.ImgsCardsInfos>
                                </a>
                    )}
            </S.CardsInfos>

            {/* Emails */}
            <S.CardsInfos>
                <a style={{textDecoration: "none", color: "white"}} href={`mailto:${loja?.emails ? loja?.emails[0]?.email : 'admmuitomaiscarros@gmail.com'}`}>
                    <S.ImgsCardsInfos>
                        <img src={emailImg} width={60} alt="E-mail" />
                        <div className='text'>
                            <span><b>EMAIL</b></span>
                            <span>{loja?.emails ? loja?.emails[0]?.email : 'admmuitomaiscarros@gmail.com'}</span>
                        </div>
                    </S.ImgsCardsInfos>
                </a>
                            
            </S.CardsInfos>

        </S.Container>
    )
}