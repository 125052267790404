import * as S from "./styles";
import { getLoja as getLojaSelector } from '../../shared/redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../App";
import { useEffect, useState } from "react";
import { getInfoLoja } from "../../shared/redux/slices/litoralAdmin";
import { Button, Input } from "antd";
import { getInfoLoja as getInfoLojaSelector } from "../../shared/redux/selectors";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { postNewsLetter } from "../../service/apiLitoral";

export const Rodape = () => {
  const infoLoja = useSelector(getInfoLojaSelector)
  const loja = useSelector(getLojaSelector)
  const dispatch = useDispatch<AppDispatch>()
  const [email, setEmail] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getInfoLoja())
  },[dispatch])

  return (
    <S.Container>
    <div className="hr" />
    {isMobile ? (
      <S.ContainerMobile>
        <div>
          <h2>Últimas novidades da Muito Mais Carros</h2>
          <h3>Seja o primeiro a saber dos veículos novos, nóticias e promoções que entram aqui na Muito Mais Carros!</h3>
          <Input onChange={(event) => {
            setEmail(event?.target.value);
          }} value={email} className='input' type='email' placeholder="Digite seu e-mail aqui" bordered={false} />
          <Button onClick={() => {
            postNewsLetter({email: email, lojaId: Number(process.env.REACT_APP_COD_LOJA)});
            setEmail("");
          }} className='button'>INSCREVA-SE</Button>
      </div>
      <div className="sobre">
        <div>
            <h2>Atendimento</h2>
            <span onClick={() => {
              window.open(loja.telefones ? `tel:+55${loja.telefones[0]?.telefone.replace(/[( )-]/g,"")}` : `tel:+55(48) 3372-0000`)
            }}>
                <i className="ri-phone-line ri-1x"></i>
                <h4>{loja?.telefones ? loja?.telefones?.filter(x => x.whatsapp === '0')[1]?.telefone: "(48) 3372-0000"}</h4>
            </span>
            <span onClick={()=> {
              window.open(loja.telefones ? `https://api.whatsapp.com/send?phone=+55${loja.telefones[1]?.telefone.replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site da *${loja?.nome_empresa}* e gostaria de mais informações.` : `https://api.whatsapp.com/send?phone=+55${"(48) 98871-3703".replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site da *${loja?.nome_empresa}* e gostaria de mais informações.`)
            }}>
            <i className="ri-whatsapp-line ri-1x"></i>
                <h4>{loja?.telefones ? loja?.telefones?.filter(x => x.whatsapp === '1')[0]?.telefone : "(48) 98871-3703"}</h4>
            </span>
            <span onClick={() => {
                  window.open(`mailto:${loja.emails ? loja.emails[0]?.email : 'admmuitomaiscarros@gmail.com'}`)
                }}>
            <i className="ri-mail-line ri-1x"></i>
                <h4>{loja?.emails ? loja.emails[0]?.email : 'admmuitomaiscarros@gmail.com'}</h4>
            </span>
        </div>
        <div>
            <h2>Institucional</h2>
            <span onClick={() => {
              navigate('/sobre-nos')
            }}>
                <h4>Sobre a empresa</h4>
            </span>
            <span onClick={() => {
              navigate('/estoque')
            }}>
                <h4>Nosso estoque</h4>
            </span>
            <span  onClick={() => {
              navigate('/contato')
            }}>
                <h4>Entre em contato</h4>
            </span>
        </div>
      </div>
      <div className="social">
          <h2>Fique conectado</h2>
          <span>
            <i onClick={() => {
                window.open(infoLoja.urlFacebook, "_blank")
              }} className="ri-facebook-fill ri-xl"></i>
              <i onClick={() => {
                window.open(infoLoja.urlInstagram, "_blank")
              }} className="ri-instagram-line ri-xl"></i>
          </span>
      </div>
      </S.ContainerMobile>
    ) : (
    <S.Content>
      <div>
          <h2>Últimas novidades da Muito Mais Carros</h2>
          <h3>Seja o primeiro a saber dos veículos novos, nóticias e promoções que entram aqui na Muito Mais Carros!</h3>
          <Input onChange={(event) => {
            setEmail(event?.target.value);
          }} value={email} className='input' type='email' placeholder="Digite seu e-mail aqui" bordered={false} />
          <Button onClick={() => {
            postNewsLetter({email: email, lojaId: Number(process.env.REACT_APP_COD_LOJA)});
            setEmail("");
          }}  className='button'>INSCREVA-SE</Button>
      </div>
      <div>
          <h2>Atendimento</h2>
          <span onClick={() => {
            window.open(loja.telefones ? `tel:+55${loja.telefones[0]?.telefone.replace(/[( )-]/g,"")}` : `tel:+55(48) 3372-0000`)
          }}>
              <i className="ri-phone-line ri-1x"></i>
              <h4>{loja?.telefones ? loja?.telefones?.filter(x => x.whatsapp === '0')[1]?.telefone: "(48) 3372-0000"}</h4>
          </span>
          <span onClick={()=> {
            window.open(loja.telefones ? `https://api.whatsapp.com/send?phone=+55${loja.telefones[1]?.telefone.replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site da *${loja?.nome_empresa}* e gostaria de mais informações.` : `https://api.whatsapp.com/send?phone=+55${"(48) 98871-3703".replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site da *${loja?.nome_empresa}* e gostaria de mais informações.`)
          }}>
          <i className="ri-whatsapp-line ri-1x"></i>
              <h4>{loja?.telefones ? loja?.telefones?.filter(x => x.whatsapp === '1')[0]?.telefone : "(48) 98871-3703"}</h4>
          </span>
          <span onClick={() => {
                window.open(`mailto:${loja.emails ? loja.emails[0]?.email : 'admmuitomaiscarros@gmail.com'}`)
              }}>
          <i className="ri-mail-line ri-1x"></i>
              <h4>{loja?.emails ? loja.emails[0]?.email : 'admmuitomaiscarros@gmail.com'}</h4>
          </span>
      </div>
      <div>
          <h2>Institucional</h2>
          <span onClick={() => {
            navigate('/sobre-nos')
          }}>
              <h4>Sobre a empresa</h4>
          </span>
          <span onClick={() => {
            navigate('/estoque')
          }}>
              <h4>Nosso estoque</h4>
          </span>
          <span  onClick={() => {
            navigate('/contato')
          }}>
              <h4>Entre em contato</h4>
          </span>
      </div>
      <div>
          <h2>Fique conectado</h2>
          <span>
            <i onClick={() => {
                window.open(infoLoja.urlFacebook, "_blank")
              }} className="ri-facebook-fill ri-xl"></i>
              <i onClick={() => {
                window.open(infoLoja.urlInstagram, "_blank")
              }} className="ri-instagram-line ri-xl"></i>
          </span>
      </div>
  </S.Content>
  )}
    
    <h6>© 2023 Muito Mais Carros | Todos os direitos reservados</h6>
</S.Container>
  );
};
