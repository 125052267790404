import styled from "styled-components";

export const Container = styled.div`
    padding: 10px 0;

    @media (max-width: 991px) {
        width: 95vw;
    }
    .input-buscar {
        border-radius: 100px;
        font-weight: 500 !important;
        font-family: Montserrat;
        padding: 10px 20px;
        width: 600px !important;
        background-color: black;
        color: white;
        border: 1px solid #393737 !important;
    }
    >div{
        display: flex;
        flex-direction: row;
        gap: 15px;
        @media (max-width: 991px) {
            flex-direction: column;
            .input-buscar {
                width: auto !important;
            }
        }
        >div{
            margin: auto;
            display: flex;
            justify-content: center;
            >input{
                border: none;
                width: 100%;
                padding: 0px 9px;
            }
        }
    }
    .mobile-div {
        display: grid;
        grid-template-columns: 8fr 1fr;
        max-width: 95vw;
        gap: 10px;
    }
    .ant-select-selector {
        border-radius: 100px !important;
        background-color: black !important;
        border: 1px solid #393737 !important;
        color: white !important;
        font-family: Montserrat !important;
        font-size: 15px;
        height: 45px !important;
        display: flex;  
        padding: 0 20px !important;
        align-items: center;
    }
    i {
        color: white !important;
    }
    .ant-select {
        width: calc(80vw / 5) !important;
        :nth-child(4), :nth-child(5) {
            width: calc(80vw / 7) !important;
        }
    }
    .ant-space {
        display: flex;
        justify-content: flex-start;
    }
    .ant-select-selection-item {
        color: white !important;
    }
    @media (max-width: 991px) {
        .ant-select {
            width: 100% !important;
            }
        }
    .div-valor {
        width: 100%;
        gap: 10px;
    }
`


export const botaoEstoque = styled.div`
    margin:40px 0px;
    text-align: center;
    display:flex;
    justify-content:center;
    z-index: 999;
    @media (max-width: 1200px) {
        margin: 0;
        width: 100%;
    }
    .icon-button {
        padding: 10px;
    }
    >a{
        background: linear-gradient(269.65deg, #CF8E4D 66.67%, #F6E18B 100%);
        text-decoration:none;
        border-radius: 100px;
        padding: 11px 60px;
        font-weight: 500;
        cursor: pointer;
        font-size: 17px;
        color: white;
        :hover{
            transition:0.2s all;
            opacity:0.8;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
    }
`